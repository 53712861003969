<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row v-show="!this.disableGrid">
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：填制凭证</strong>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}{{formData.is_posting?'已过账':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                           <!-- <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="会计期间:" label-width="110px">
                                    <el-input v-model="formData.accounting_period" type="date"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>-->
                            <el-col :lg="1" :sm="12" :md="8">
                                <el-link class="pull-left" style="margin-top: 11px;cursor:default" :underline="false" type="success">会计期间</el-link>
                            </el-col>
                            <el-col :lg="2" :sm="12" :md="8">
                                <el-date-picker
                                        class="pull-left"
                                        :clearable="false"
                                        style="margin-top: 8px;width: 128px"
                                        v-model="formData.accounting_period"
                                        :readonly="true"
                                        size="mini"
                                        type="month"
                                        value-format="yyyy-MM-dd"
                                        placeholder="由凭证日期生成">
                                </el-date-picker>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="3">
                                <el-tooltip class="item" effect="dark"
                                            :content="`单号唯一ID:${formData.id ? formData.id : '自动生成'}`" placement="top" :open-delay = "750">
                                    <el-form-item label="凭证总号:">
                                        <el-input v-model="formData.id" placeholder="自动生成"
                                                  :readonly="true"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" v-model="formData.registrar" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('create_date')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="创建时间:">
                                    <el-input ref="create_date" v-model="formData.create_date"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('customer')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_cash" v-model="formData.last_name_cash"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('bill_type')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_cash" v-model="formData.last_date_cash"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('last_name_cash')"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :sm="12" :md="4">
                                <el-form-item label="凭证日期:">
                                    <el-date-picker
                                            style=" width: 140px"
                                            v-model="formData.voucher_date"
                                            :clearable="false"
                                            @blur="voucherDateBlur"
                                            :disabled="formData.is_cancel || formData.is_posting"
                                            @change="voucherDateChangeEvent"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-tooltip class="item" effect="dark"
                                            :content="`单号唯一ID:${formData.id ? formData.id : '自动生成'}`" placement="top" :open-delay = "750">
                                        <el-input style=" margin-top: 7px;" v-model="formData.type_id" placeholder="可自动生成"
                                                  :disabled="formData.is_cancel || formData.is_posting"
                                                  size="mini">
                                            <template slot="prepend">字第</template>
                                            <template slot="append">号</template>
                                        </el-input>
                                </el-tooltip>
                            </el-col>

                            <el-col :sm="12" :md="3">
                                <el-form-item label="附原始单据:" label-width="100px">
                                    <el-input v-model="formData.original_bills" type="number" size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计借方金额:" label-width="110px">
                                    <el-input v-model="formData.borrow_money_total" :readonly="true"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计贷方金额:" label-width="110px">
                                    <el-input v-model="formData.lent_money_total" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="出纳单号:">
                                    <el-input v-model="formData.cash_type_id" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('create_date')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :md="6">
                                <el-button v-if="!this.isBlankVue(formData.only_bill_id_voucher)"
                                           style=" margin-top: 8px; margin-left: 8px; " type="primary"
                                           :disabled="this.isBlankVue(formData.only_bill_id_voucher)"
                                           @click="$_openType({path: '/receipt', query: {id : formData.only_bill_id_voucher}})" size="mini">查询出纳单
                                </el-button>
                                <el-button v-if="formData.bill_id_type == 'KD'"
                                           style=" margin-top: 8px; margin-left: 8px; " type="primary"
                                           :disabled="this.isBlankVue(formData.id)"
                                           @click="$_openType({path: '/allWarehouse/allSalesBilling', query: {voucher_id : formData.id,pickerDate:[$_formatDate($getMonthFirst(formData.voucher_date), 'yyyy-MM-dd'),$_formatDate($getMonthLast(formData.voucher_date), 'yyyy-MM-dd')]}})" size="mini">查询销售开单
                                </el-button>
                                <el-button v-if="formData.bill_id_type == 'JC'"
                                           style=" margin-top: 8px; margin-left: 8px; " type="primary"
                                           :disabled="this.isBlankVue(formData.id)"
                                           @click="$_openType({path: '/allIntoWarehouse', query: {voucher_id : formData.id,pickerDate:[$_formatDate($getMonthFirst(formData.voucher_date), 'yyyy-MM-dd'),$_formatDate($getMonthLast(formData.voucher_date), 'yyyy-MM-dd')]}})" size="mini">查询进仓单
                                </el-button>
                                <el-button style=" margin-top: 8px; margin-left: 8px; " type="primary"
                                           :disabled="this.formData.is_cancel || formData.is_posting"
                                           @click="saveOrUpdateVoucher" size="mini">保存
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/voucher/setIsCancel'])"
                                           style=" margin-top: 8px; " type="danger" round
                                           :disabled="this.isBlankVue(this.idList) || formData.is_posting"
                                           @click="setIsCancel()" size="mini">作废
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="success" round
                                           @click="newBuild()" size="mini">新建
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="$_openType({path: '/voucher', query: {id : formData.voucher_id,copy:true}})"
                                           size="mini">复制
                                </el-button>
                            </el-col>
                            <el-col :md="1">
                                <vxe-toolbar style=" height: 42px; " export ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :lg="24">
                                <vxe-grid
                                        border
                                        show-overflow
                                        keep-source
                                        ref="gridVoucher"
                                        id="gridVoucher"
                                        align="center"
                                        size="mini"
                                        :height="(this.getViewHeight() - 170)+''"
                                        highlight-current-row
                                        show-footer
                                        :footer-method="footerMethod"
                                        :print-config="{}"
                                        :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true,original:true}"
                                        @keydown="$_onGridKeydown"
                                        @cell-click="cellClickEventMain"
                                        :cell-class-name="cellClassName"
                                        :header-row-class-name="headerRowClassName"
                                        :data="tableDataMain"
                                        :columns="tableColumn"
                                        :mouse-config="{selected: true}"
                                        :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                        :keyboard-config="{enterToTab:false,isArrow: false, isDel: false, isEnter: false, isTab: true,isEdit:false,enabled:!formData.is_cancel || !formData.is_posting}">
                                    <!--操作-->
                                    <template #operate="{row,rowIndex}">
                                        <el-popover
                                                placement="left-start"
                                                v-model="row.operateVisible"
                                                width="360">
                                            <p>插入行：向上插入一行</p>
                                            <p>复制行：向下复制一行</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button type="primary" size="mini" @click="row.operateVisible = false,$refs.gridVoucher.clearData(row)">清空行</el-button>
                                                <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertRowEvent(tableDataMain,$refs.gridVoucher,row,rowIndex)">插入行</el-button>
                                                <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertCopyRowEvent(tableDataMain,$refs.gridVoucher,row,rowIndex)">复制行</el-button>
                                                <el-button type="primary" size="mini" @click="row.operateVisible = false,$_deleteRowEvent(tableDataMain,$refs.gridVoucher,row,rowIndex)">删除行</el-button>
                                                <el-button size="mini" type="text" @click="row.operateVisible = false">取消</el-button>
                                            </div>
                                            <vxe-button size="mini" icon="el-icon-plus" slot="reference" circle></vxe-button>
                                        </el-popover>
                                    </template>
                                    <!--自定义插槽-->
                                    <template #money_heard="{column}">
                                        <div class="first-col">
                                            <div class="jine_box_title">{{column.title}}</div>
                                            <div class="jine_box_danwei">
                                                <span>亿</span>
                                                <span>千</span>
                                                <span>百</span>
                                                <span>十</span>
                                                <span>万</span>
                                                <span>千</span>
                                                <span>百</span>
                                                <span>十</span>
                                                <span>元</span>
                                                <span>角</span>
                                                <span>分</span>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- 摘要查询 提示 -->
                                    <template #summary_case_data_edit="{row}">
                                        <vxe-pulldown ref="xDownSummary" transfer>
                                            <template #default>
                                                <vxe-input v-model="row.summary" clearable
                                                           suffix-icon="fa fa-search"
                                                           :disabled="formData.is_cancel || formData.is_posting"
                                                           @keyup="keyUpDropDownEvent(dropDownTableDataSummary,$event,'dropDownGridSummary','xDownSummary','xDownSummaryIsShow')"
                                                           @focus="$_searchSummaryList(row.summary),$refs['xDownSummary'].showPanel(),xDownSummaryIsShow=true"
                                                           @blur="xDownSummaryIsShow=false"
                                                           @change="$_searchSummaryList(row.summary),dropDownTablePageSummary.currentPage = 1"></vxe-input>
                                            </template>
                                            <template #dropdown>
                                                <div class="my-dropdown4">
                                                    <vxe-grid
                                                            ref="dropDownGridSummary"
                                                            border
                                                            resizable
                                                            show-overflow
                                                            keep-source
                                                            auto-resize
                                                            size="mini"
                                                            height="250"
                                                            :radio-config="{highlight: true,trigger:'row'}"
                                                            :loading="loading"
                                                            :pager-config="dropDownTablePageSummary"
                                                            :data="dropDownTableDataSummary"
                                                            :columns="dropDownTableColumnSummary"
                                                            @radio-change="radioSummaryChangeEvent"
                                                            @page-change="pageChangeSummaryEvent">
                                                    </vxe-grid>
                                                </div>
                                            </template>
                                        </vxe-pulldown>
                                    </template>

                                    <!-- 科目编码  查询 提示 -->
                                    <!--重要：如果下拉框上下选择，用到getActiveRecord 默认会去掉 隐藏的行，所以隐藏行需要放到最后面-->
                                    <template #subject_id_edit="{row}">
                                        <vxe-pulldown ref="xDownSubjectId" transfer>
                                            <template #default>
                                                <vxe-input v-model="row.subject_id" clearable
                                                           @clear="clearSujectEvent(row)"
                                                           suffix-icon="fa fa-search"
                                                           :disabled="formData.is_cancel || formData.is_posting"
                                                           @keyup="keyUpDropDownEvent(dropDownTableData,$event,'dropDownGrid','gridVoucher','xDownSubjectId','xDownSubjectIdIsShow')"
                                                           @focus="$_searchSubjectList(row.subject_id),$refs.xDownSubjectId.showPanel(),xDownSubjectIdIsShow=true"
                                                           @blur="xDownSubjectIdIsShow=false,$_cheakHasSubject(row)"
                                                           @input="dropDownTablePage.currentPage = 1"
                                                           @change="$_searchSubjectList(row.subject_id)"></vxe-input>
                                            </template>
                                            <template #dropdown>
                                                <div class="my-dropdown4">
                                                    <vxe-grid
                                                            ref="dropDownGrid"
                                                            border
                                                            resizable
                                                            show-overflow
                                                            keep-source
                                                            auto-resize
                                                            size="mini"
                                                            height="250"
                                                            :radio-config="{highlight: true,trigger:'row'}"
                                                            :loading="loading"
                                                            :pager-config="dropDownTablePage"
                                                            :data="dropDownTableData"
                                                            :columns="dropDownTableColumn"
                                                            @cell-click="radioChangeEvent($event,true,'dropDownGrid','gridVoucher','xDownSubjectId','xDownSubjectIdIsShow')"
                                                            @radio-change="radioChangeEvent($event,true,'dropDownGrid','gridVoucher','xDownSubjectId','xDownSubjectIdIsShow')"
                                                            @page-change="pageChangeEvent">
                                                    </vxe-grid>
                                                </div>
                                            </template>
                                        </vxe-pulldown>
                                    </template>

                                    <!-- 借方金额 -->
                                    <template #borrow_money_edit="{row}">
                                        <vxe-input
                                                v-model="row.borrow_money" type="float" clearable
                                                @change="sumBorrowMoney"
                                                :disabled="formData.is_cancel || formData.is_posting"
                                                :controls="false"></vxe-input>
                                    </template>
                                    <!-- 贷方金额 -->
                                    <template #lent_money_edit="{row}">
                                        <vxe-input
                                                v-model="row.lent_money" type="float" clearable
                                                @change="sumLentMoney"
                                                :disabled="formData.is_cancel || formData.is_posting"
                                                :controls="false"></vxe-input>
                                    </template>


                                </vxe-grid>

                            </el-col>
                        </el-row>

                    </el-col>
                </el-row>
            </el-form>
            <vxe-modal v-model="showAssistModel" :lock-view="false" :mask="false" resize
                       margin-size="-500" width="400" title="辅助核算">
                <template #default>
                    <el-form>
                        <el-row>
                            <el-col :lg="18" :md="18">
                                <el-form-item label="往来单位:">
                                    <el-select v-model="tempCompanyAssistModel" value-key="company_name"
                                               default-first-option remote
                                               :remote-method="$_searchAllCompany" clearable filterable size="mini"
                                               placeholder="可搜索查询">
                                        <el-option
                                                v-for="item in $store.getters.getAllCompanyList"
                                                :key="item.company_uuid"
                                                :label="item.company_name"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="4" :md="4">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " @click="$_okSetAssist"
                                           type="primary" size="mini">确认
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </template>
            </vxe-modal>
        </el-main>
    </div>
</template>

<script>




    export default {

        data() {
            return {
                idList:'',
                tempCompanyAssistModel: '',//弹窗辅助项目
                tempGrid: '',
                tempRow: {},//用于判断是否存在辅助项目，存在则缓存起来当前行，当选中点击确定，直接赋值
                showAssistModel: false,
                rowMain: {},
                showModel: {
                    isShow: false,
               },
                tableDataModel: [],
                tableDataModelPage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [50, 100, 200, 500, 1000, 2000, 5000]
               },
                filterData: {},
                sortData: {},
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date, date]);
                       }
                   }, {
                        text: '最近周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date, new Date()]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }]
               },
                openCashTypeId: '',//用于跳转的单号
                disableGrid: false,
                xDownSummaryIsShow: false,
                xDownSubjectIdIsShow: false,
                loading: false,
                dropDownTableColumn: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'id_subject', title: '科目编码', width: 90},
                    {field:'subject_name', title: '科目名称', width: 210},
                    {field:'fater_name', title: '上级科目', width: 210},
                ],
                dropDownTableData: [],
                dropDownTablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },
                dropDownTableColumnSummary: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'id', title: '编码', width: 100},
                    {field:'summary_name', title: '常用摘要', width: 220},
                ],
                //摘要
                dropDownTableDataSummary: [],
                dropDownTablePageSummary: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },
                formData: {
                    id: '',//凭证总号 ID
                    copy: false,
                    is_posting: false,
                    accounting_period: '',//会计期间
                    borrow_money_total: '',//合计借
                    lent_money_total: '',//合计贷
                    type_id : '',//对应不同类型的记、转、收、付、
                    voucher_date: new Date(),//凭证日期
                    company_name : '',//付款单位
                    company_id : '',//付款单位Id
                    original_bills: '',//附带原始单据
                    registrar: '',//记录人
                    last_name_cash: '',//最后修改人
                    last_date_cash: '',//最后修改日期
                    create_date: '',//创建日期
                    is_cancel: false,//作废
                    is_delete : false,//删除
                    only_bill_id_voucher: '',//关联单据 唯一单号
                    voucher_id: '',//凭证id
                    cash_type_id: '',//出纳单 凭证字
                    cash_id: '',//出纳单id
                    //excessToAdvance: '',//超收转订金
                    //idSDJ: '',//超收转订金(单号)
               },
                start_date: '',
                tableColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'summary',
                        width: 300,
                        title: '摘要',
                        slots: {edit: 'summary_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_id_and_name',
                        width: 380,
                        title: '会计科目',
                        slots: {edit: 'subject_id_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   }, {
                        field:'company_name_assist',
                        width: 200,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {
                        field:'borrow_money',
                        width: 222,
                        title: '借方金额',
                        formatter: ({cellValue}) => {
                            return this.formatMoneyVoucher(cellValue ? cellValue : '')
                       },
                        slots: {header: 'money_heard', edit: 'borrow_money_edit'},
                        titleHelp: {message: '含税\n单位(吨) 产品金额= 过磅重*吨价\n单位(支) 产品金额= 支数*每支单价'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   }, {
                        field:'lent_money',
                        width: 222,
                        title: '贷方金额',
                        formatter: ({cellValue}) => {
                            return this.formatMoneyVoucher(cellValue ? cellValue : '')
                       },
                        slots: {header: 'money_heard', edit: 'lent_money_edit'},
                        titleHelp: {message: '含税\n单位(吨) 产品金额= 过磅重*吨价\n单位(支) 产品金额= 支数*每支单价'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },  {
                        field:'cash_data_remark',
                        width: 222,
                        className: 'unEditor',
                        title: '备注',
                    },   {
                        field:'system_remarks',
                        width: 222,
                        className: 'unEditor',
                        title: '系统备注',
                    }, {
                        field:'subject_id',
                        width: 122,
                        className: 'unEditor',
                        title: '科目编码',
                        visible: false,
                   },
                    {
                        field:'subject_name',
                        width: 222,
                        className: 'unEditor',
                        visible: false,
                        title: '科目名称'
                   },
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}},
                ],

                tableDataMain: [],
           }
       },
        methods: {
            voucherDateChangeEvent() {
                this.isDataChanage = true,
                this.formData.accounting_period = this.formData.voucher_date
           },
            sumBorrowMoney() {
                let v = 0
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    v = this.$XEUtils.add(v, this.tableDataMain[i].borrow_money);
               }
                this.formData.borrow_money_total = v;
           },
            sumLentMoney() {
                let v = 0
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    v = this.$XEUtils.add(v, this.tableDataMain[i].lent_money);
               }
                this.formData.lent_money_total = v;
           },
            searchVoucherById() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/voucher/searchVoucherById'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    params: {
                        id: this.formData.id,
                        copy: this.formData.copy
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status == 200) {
                        this.tableDataMain = this.createRowDataByvoucher(response.data.tableDataMain)
                        this.formData = response.data.formData
                        this.idList = this.formData.voucher_id
                        this.sumLentMoney();
                        this.sumBorrowMoney();
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            //保存或更新凭证
            saveOrUpdateVoucher() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/voucher/saveOrUpdateVoucher',
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                        });
                        //刷新当前路径
                        this.$router.push({path: '/voucher', query: {id : response.data.voucher.id}})
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            radioSummaryChangeEvent({row}) {
                this.radioSummaryChangeEnterEvent(row);
           },
            radioSummaryChangeEnterEvent(row) {
                if (row != null) {
                    const activeRow = this.$refs.gridVoucher.getActiveRecord().row;
                    activeRow.summary = row.summary_name;
               }
                this.$refs['xDownSummary'].hidePanel();
                this.xDownSummaryIsShow = false
           },
            /**
             * 选中摘要下拉 CashDataxGrid
             * @param row
             */
            pageChangeSummaryEvent({currentPage, pageSize}) {
                this.dropDownTablePageSummary.currentPage = currentPage
                this.dropDownTablePageSummary.pageSize = pageSize
                const activeRow = this.$refs.gridVoucher.getActiveRecord().row;
                this.$_searchSummaryList(activeRow.summary);
           },
            //（下拉主数据 用于设置上下选择行的位置）、事件、弹框的GridRef、赋值GridRef、下拉框是否显示
            keyUpDropDownEvent(dropDownTableData, event, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                //console.log(xDown);
                //console.log('this.$refs[xDown].isPanelVisible()', this.$refs[xDown].isPanelVisible());
                ////console.log(dropDownGrid,this.$refs[dropDownGrid],dropDownTableData)
                ////console.log(event.$event.code)
                let i = 0;
                if (event.$event.code == 'Enter' || event.$event.code == 'NumpadEnter') {
                    //this.$refs[dropDownGrid].getRadioRow();
                    i = this.$refs[dropDownGridRef].getRowIndex(this.$refs[dropDownGridRef].getRadioRecord());
                    //摘要
                    if (dropDownGridRef == 'dropDownGridSummary') {
                        this.radioSummaryChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord());
                        this.$refs['xDownSummary'].hidePanel();
                        this.xDownSummaryIsShow = false;
                   }
                    if (dropDownGridRef == 'dropDownGrid' || dropDownGridRef == 'dropDownGridFooter') {
                        //回车才出发是否继续查询有没有子类
                        this.radioChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord(), true, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow);
                   }

                    //console.log('Enter', i);
               } else if (event.$event.code == 'ArrowUp' || event.$event.code == 'ArrowDown') {
                    //console.log("执行上下箭头", event.$event.code);
                    if (this.$refs[dropDownGridRef].getRadioRecord() != null) {
                        i = this.$refs[dropDownGridRef].getRowIndex(this.$refs[dropDownGridRef].getRadioRecord());
                   }
                    if (event.$event.code == 'ArrowUp') {
                        if (i > 0) {
                            i = i - 1;
                       }
                   } else if (event.$event.code == 'ArrowDown') {
                        ////console.log('dropDownTableData.length',dropDownTableData.length)
                        if (this.$refs[dropDownGridRef].getRadioRecord() == null) {
                            i = 0;
                       } else {
                            i = i + 1;
                       }
                   }
                    ////console.log("dropDownTableData: " + dropDownTableData)
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[i]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[i])
               }
                ////console.log('this.$refs[dropDownGrid]', this.$refs[dropDownGrid])
                if (i != 0) {
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[i]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[i])
               } else {
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[0]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[0])
               }

           },
            pageChangeEvent({currentPage, pageSize}) {
                this.dropDownTablePage.currentPage = currentPage
                this.dropDownTablePage.pageSize = pageSize
                this.$_searchSubjectList();
                // const activeRow = this.$refs.baseGird.getActiveRecord().row;
                //this.searchJCWarehouse(activeRow.specification);
           },
            /**
             * 选中科目编码下拉，用于event {row}转row
             * @param row
             */
            radioChangeEvent({row}, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                this.radioChangeEnterEvent(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow)
           },
            radioChangeEnterEvent(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                if (row != null) {
                    this.$_isHasFatherId(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow)
                    //row.id 查询判断是否有子类，如果有的话，继续查询
               }
           },

            cellClassName({row, column}) {
                if (column.property == 'borrow_money' || column.property == 'lent_money') {
                    let money = row[column.property];
                    if (money < 0) {
                        //console.log('row', row);
                        //console.log('money', money);
                        return 'jine_td jine_td_red'
                   } else {
                        return 'jine_td'
                   }

               }
           },
            headerRowClassName() {
                return 'header-row-class'
           },
            //如果为空，则自动添加：当天日期
            voucherDateBlur() {
                if (this.isBlankVue(this.formData.voucher_date)) {
                    this.formData.voucher_date = new Date();
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count.toFixed(2)
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'borrow_money' || column.property == 'lent_money') {
                            sums.push(this.sumNum(data, column.property))
                            //sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            //单击
            cellClickEventMain({row, column}) {
                if (column.property == 'summary' || column.property == 'subject_id') {
                    const $table = this.$refs.gridVoucher
                    // 重写默认的覆盖式，改为追加式
                    $table.setActiveCell(row, column)
               }
                this.rowMain = row;
           },
            createRowDataByvoucher(list) {
                if (list == null) {
                    list = [];
               }
                for (var index = list.length; index < 30; index++) {
                    list.push({
                        id: '',
                        company_name_assist : '',//companyNameAssist
                        company_uuid_assist : '',//company_uuid_assist
                        summary: '',//摘要
                        subject_id : '',//科目编码
                        subject_name : '',//科目名称
                        subject_id_and_name : '',//科目编码&科目名称
                        borrow_money : '',//借
                        lent_money : '',//贷
                        order_id : index + 1,
                        operateVisible : false,//是否显示操作弹层
                   })
               }
                return list;
           },
            clearSujectEvent(row) {
                row.subject_id = '',//科目编码
                    row.subject_name = '',//科目名称
                    row.subject_id_and_name = ''//科目编码&科目名称
                row.company_name_assist = ''//辅助项
                row.company_uuid_assist = ''//辅助项
           },
            newBuild(){
                if (this.$_isGridDataChange(this.$refs['gridVoucher']) || this.$_isGridDataChange(this.$refs['gridVoucher']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: '当前数据已被修改，请先保存，再操作！如果不想保存，按F5刷新当前页！',
                        type: 'error'
                    });
                }else{
                    this.$router.push({path: '/voucher'})
                }
            },
            setIsCancel() {
                //console.log(this.$refs.xGrid.getCheckboxRecords());
                //console.log('selectRecords', selectRecords);
                let msg = '您确定要作废：'+this.idList+'单号?作废后不能修改，不能反作废。';
                //console.log('this.idList.length: ' + this.idList.length)
                //console.log('this.idList.substr(0,20): ' + this.idList.substr(0,20))
                if (this.idList.length > 20){
                    msg = '您确定要作废：'+this.idList.substr(0,20)+'...等等单号?作废后不能修改，不能反作废。';
                }
                this.$XModal.confirm(msg).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        //传送删除动作
                        this.$axios({
                            method:'post',
                            url:'/admin/voucher/setIsCancel',
                            data: {
                                id:this.idList
                            }
                        }).then((response) =>{         //这里使用了ES6的语法
                            loading.close();
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.searchVoucherById();
                                //this.getAllVoucher();
                            }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) =>{
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
       }, computed: {},
        created() {
            this.formData.id = this.$route.query.id;
            this.formData.copy = this.$route.query.copy;
            if (this.isBlankVue(this.formData.id)) {
                var list = [];
                this.tableDataMain = this.createRowDataByvoucher(list);

           } else {
                this.searchVoucherById();
           }
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.gridVoucher.connect(this.$refs.xToolbar2);

            })

           //this.loading = true;
       }
   };


</script>

<style scoped>
    .vxe-pulldown.size--mini {
        width: 100% !important;
    }

    /deep/ table .el-input--mini .el-input__inner, table .el-input-number--mini {
        height: 28px;
        line-height: 28px;
   }

    /deep/ .el-input-group__prepend {
        border: initial;
   }

    .jine_box_title {
        height: 25px;
        line-height: 25px;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #eee;
   }

    .jine_box_danwei {
        /*display: -webkit-inline-box;*/
        display: -ms-inline-flexbox;
        display: inline-flex;
        display: -webkit-inline-flex;
        width: 100%;
        height: 22px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANsAAAAUCAMAAAAz47gTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9QTFRF6enpp9n17bm55ubmvJyc+JGLrgAAAAV0Uk5T/////wD7tg5TAAAAQklEQVR42uzPsQ0AIAwDsBb4/2YOYCkCqYszRsngWGdmscum7yju4qHLpu/47GBjY2NjY2NjY2NjY2NjY7u3bQEGAIG/Qbbiioa2AAAAAElFTkSuQmCC);
   }

    .jine_box_danwei span {
        font-size: 12px;
        width: 20px;
        height: 100%;
        line-height: 22px;
        display: inline-block;
        text-align: center;
        font-weight: 400;
   }







    /deep/ .vxe-table--render-default.size--mini .vxe-header--column:not(.col--ellipsis) {
        padding: 0px 0px;
   }



    /deep/.el-input>input[readonly] {
        background-color: #EEEEEE;
   }

    /*/deep/ .vxe-table--render-default.border--full .vxe-body--column, .vxe-table--render-default.border--full .vxe-footer--column, .vxe-table--render-default.border--full .vxe-header--column {
        background-image: -webkit-gradient(linear,left top, left bottom,from(#e8eaec),to(#e8eaec)),-webkit-gradient(linear,left top, left bottom,from(#e8eaec),to(#e8eaec));
        background-image: linear-gradient(#e8eaec,#e8eaec),linear-gradient(#e8eaec,#e8eaec);
        background-repeat: initial;
        background-size: initial;
        background-position: initial;
   }*/

</style>
